<template>
	<div>
		<b-row>			
			<b-colxx xxs="12">

				 <b-card class="mb-4" :title="$t('Dispositivos')">
				 	<div class="topButtonsBar">			
						<button variant="primary" class="btn mt-4 btn-outline-success"  v-b-modal.modalDispositivo>Agregar nuevo</button>

                        	  <b-modal id="modalDispositivo" ref="modalDispositivo" :title="$t('Registrar nueva tarjeta')">
							      <b-row>
							        <b-colxx xxs="12">
							              <b-form >
							                <b-row>
							                  	<b-colxx xxs="12">
									              <b-form>
									                <b-row>
									                  <b-colxx sm="12">
									                    <b-form-group :label="$t('Número Serial')">
									                      <b-form-input v-model="registroDispositivo.serial" ></b-form-input>
									                    </b-form-group>
									                  </b-colxx>

									                  <b-colxx sm="12">
									                    <b-form-group :label="$t('Observaciones')">
									                      <b-form-textarea v-model="registroDispositivo.observaciones" ></b-form-textarea>
									                    </b-form-group>
									                  </b-colxx>
									                </b-row>

									              </b-form>
									        	</b-colxx>
							                </b-row>
							              </b-form>
							            <!--</b-card>-->
							        </b-colxx>
							      </b-row>
							      <template slot="modal-footer">
							          <b-button variant="primary" @click="crearDispositivo()" class="mr-1">Guardar</b-button>
							          <b-button variant="secondary" @click="hideModal('modalDispositivo')">Cancelar</b-button>
							      </template>
							  </b-modal>
				 	</div>
			        <b-table
			            ref="custom-table"
			            class="vuetable"
			            sort-by="fechaCreacion" sort-desc="true"
			            @row-selected="rowSelected"
			            selectable
			            :select-mode="bootstrapTable.selectMode"
			            :current-page="currentPage"
			            selectedVariant="primary"
			            :fields="bootstrapTable.fields"
			            :items="dataArray"
			            :per-page="perPage"
			          >
			          <template slot="status" slot-scope="data">
			            <b-badge class="mb-1" :variant="data.item.statusColor">{{ data.value }}</b-badge>
			          </template>
			        </b-table>
			        <b-pagination
			            size="sm"
			            align="center"
			            :total-rows="totalRows"
			            :per-page="perPage"
			            v-model="currentPage"
			          >
			            <template v-slot:next-text>
			              <i class="simple-icon-arrow-right"/>
			            </template>
			            <template v-slot:prev-text>
			              <i class="simple-icon-arrow-left"/>
			            </template>
			            <template v-slot:first-text>
			              <i class="simple-icon-control-start"/>
			            </template>
			            <template v-slot:last-text>
			              <i class="simple-icon-control-end"/>
			            </template>
			          </b-pagination>
			      	</b-card>
			      	<b-card :title="$t('Detalle dispositivo')" v-if="bootstrapTable.selected.length>0">
					    <b-colxx xxs="12">				          <b-form @submit.prevent="onGridFormSubmit">
				            <b-row>
				              <b-colxx sm="6">
				                <b-form-group :label="$t('Serial')">
				                  <b-form-input readonly type="text" v-model="selected.serial" />
				                </b-form-group>
				              </b-colxx>
				              <b-colxx sm="6">
				                <b-form-group :label="$t('Observaciones')">
				                  <b-form-input v-model="selected.observaciones" ></b-form-input>
				                </b-form-group>
				              </b-colxx>
				              <b-colxx sm="12">
				                <b-form-group v-if="llaveGenerada.length > 0" :label="$t('Llave de activación generada')">
				                  <b-form-input  v-model="llaveGenerada"></b-form-input>
				                </b-form-group>

				                <b-button type="button" variant="primary" class="mt-4" v-on:click="generarLlave(selected);">Generar llave de activación</b-button>
				              </b-colxx>
				            </b-row>
				          </b-form>
					    </b-colxx>
			      	</b-card>
			      	<b-card :title="$t('Historial de carga')" v-if="bootstrapTable.selected.length>0">
					    <b-colxx xxs="12">				        
				            <b-row>				              
				              <b-colxx sm="12">
				                <b-table
						            ref="custom-table"
						            class="vuetable"
						            sort-by="fechaCreacion" sort-desc="true"
						            selectedVariant="primary"
						            :fields="chargeHistory.fields"
						            :items="selectedHistory"
						            :per-page="perPage"
						          >
						          <template slot="status" slot-scope="data">
						            <b-badge class="mb-1" :variant="data.item.statusColor">{{ data.value }}</b-badge>
						          </template>
						        </b-table>
				              </b-colxx>
				            </b-row>
					    </b-colxx>
			      	</b-card>
			</b-colxx>
		</b-row>
	</div>
</template>
<script>

import {
	request,
	mapGetters,
	mapActions
} from "vuex";

import axios from 'axios'

	export default{
		data(){
			return{
				currentPage: 1,
			    perPage: 5,
			    bootstrapTable: {
			        selected: [],
			        selectMode: 'single',
			        fields: [
			          	{ key: 'serial', label: 'No. Serial', sortable: true, sortDirection: 'desc', tdClass: 'list-item-heading' },
			          	{ key: 'fechaCreacion', label: 'Fecha de captura', sortable: true, tdClass: 'text-muted' , formatter: function(valueFecha){
												return new Date(valueFecha).toLocaleString("es-MX");
											}
						}
			        ]
			    },
			    chargeHistory: {
			    	fields:[
			    		{ key: 'chargeCount', label:'Cuenta de carga', sortable: true, sortDirection:'desc', tdClass: 'list-item-heading' },
			    		{ key: 'fechaHoraRegistro', label:'Fecha de registro', sortable: true, sortDirection:'desc', tdClass: 'list-item-heading',formatter: function(valueFecha){
												return new Date(valueFecha).toLocaleString("es-MX");
											} 
						}
			    	]
			    },
			    dataArray: [],
			    selectedHistory:[],
			    registroDispositivo: {
			    	serial: "",
			    	observaciones: ""
			    },
			    nuevaLlaveActivacion: "",
			    llaveGenerada: ""
			}
		},

		computed: {
			totalRows(){
				return this.dataArray.length;
			},
			selected(){
				return this.bootstrapTable.selected[0];
			}
		},

		mounted: function(){
			this.dataPaginator();
		},

		methods:{
			...mapActions(["getAll", "create", "update"]),

			dataPaginator () {
		      	var instance = this;
		      	var token = JSON.parse(localStorage.getItem('user')).token;
		      	let payload = {
		      		endpoint: "/api/dispositivo",
		      		token: token
		      	}

		      	instance.$parent.toggleLoader("Obteniendo datos");

				this.getAll(payload).then((response) => {
					instance.$parent.toggleLoader();
					instance.dataArray = response.data.data;
					instance.currentPage = 1;
				}).catch(_error => {
					instance.$parent.toggleLoader();
					instance.dataArray = [];
					instance.currentPage = 1;
					instance.totalRows = 1;
				});
		    },

		    getChargeHistory(selected){
		    	var instance = this;
		      	var token = JSON.parse(localStorage.getItem('user')).token;
		      	let payload = {
		      		endpoint: "/api/dispositivo/chargeCount/" + selected.id,
		      		token: token
		      	}

		      	//instance.$parent.toggleLoader("Obteniendo datos");

				this.getAll(payload).then((response) => {
					//instance.$parent.toggleLoader();
					instance.selectedHistory = response.data.data;
				}).catch(_error => {
					//instance.$parent.toggleLoader();
					instance.selectedHistory= [];
				});
		    },

		    crearDispositivo (){
		    	var instance = this;
		      	var token = JSON.parse(localStorage.getItem('user')).token;
		      	let payload = {
		      		endpoint: "/api/dispositivo",
		      		token: token,
		      		data: instance.registroDispositivo
		      	}

		      	instance.$parent.toggleLoader("Enviando datos de dispositivo");

				this.create(payload).then((response) => {

					instance.$parent.toggleLoader();
					instance.$toasted.success("Dispositivo creado exitosamente");
					instance.hideModal("modalDispositivo");
					instance.dataPaginator();
					
				}).catch(_error => {
					instance.$parent.toggleLoader();
					instance.$toasted.error("Ocurrió un error al crear el dispositivo");
					instance.dataArray = [];
					instance.currentPage = 1;
					instance.totalRows = 1;
				});
		    },

		    generarLlave (selected){
		    	var instance = this;
		      	var token = JSON.parse(localStorage.getItem('user')).token;
		      	let payload = {
		      		endpoint:  "/api/dispositivo/" + selected.id + "/generar-dvkey",
		      		token: token
		      	}

		      	instance.$parent.toggleLoader("Generando llave");

				this.update(payload).then((response) => {
					instance.$parent.toggleLoader();
					instance.$toasted.success("Llave generada con éxito");
					//Mostrar llave
					instance.llaveGenerada = response.data.data;
				}).catch(_error => {
					instance.$parent.toggleLoader();
					instance.$toasted.error("Error al generar llave");
					instance.dataArray = [];
					instance.currentPage = 1;
					instance.totalRows = 1;
				});	
		    },
		    

		    apiParamsConverter (params) {
		      let apiParams = {}
		      if (params.perPage !== 0) {
		        apiParams.per_page = params.perPage
		      }
		      if (params.currentPage >= 1) {
		        apiParams.page = params.currentPage
		      }
		      if (params.sortBy && params.sortBy.length > 0) {
		        apiParams.sort = `${params.sortBy}|${params.sortDesc ? 'desc' : 'asc'}`
		      }
		      if (params.filter && params.filter.length > 0) {
		        // Optional
		      }
		      return apiParams
		    },
		    onPaginationData (paginationData) {
		      this.$refs.pagination.setPaginationData(paginationData)
		    },
		    onChangePage (page) {
		      this.$refs.vuetable.changePage(page)
		    },
		    rowSelected (items) {
		      this.bootstrapTable.selected = items;
		      this.llaveGenerada = "";
		      this.getChargeHistory(this.selected);
		      console.log(this.selected.id);
		    },
		    hideModal (refname) {
		        this.$refs[refname].hide()

		        if (refname === 'modalnestedinline') {
		          this.$refs['modalnested'].show()
		        }
		    },
		}
	}
</script>